import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import WhySurveyImg1 from ".././../../assets/img/why-survey-img1.png";
import WhySurveyImg2 from ".././../../assets/img/why-survey-img2.png";
import WhySurveyImg3 from ".././../../assets/img/why-survey-img3.png";
import WhySurveyImg4 from ".././../../assets/img/why-survey-img4.png";

class WhySurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
      ],
    };
    return (
      <div className="survey-section-container d-block w-100 py-5">
        <Container>
          <div className="d-block w-100 text-center pb-5 heading">
            Why SurvHaNa?
          </div>
          <Slider {...settings}>
            <div className="d-flex justify-content-center w-100 flex-wrap text-center px-3 box">
              <div className="d-inline-flex p-3 mb-3 image">
                <img src={WhySurveyImg1} alt="User-Friendly Interface" />
              </div>
              <h4>User-Friendly Interface</h4>
              <p>
                SurvHaNa's interface is designed to be intuitive, making survey
                creation and management a breeze
              </p>
            </div>
            <div className="d-flex justify-content-center w-100 flex-wrap text-center px-3 box">
              <div className="d-inline-flex p-3 mb-3 image">
                <img src={WhySurveyImg2} alt="User-Friendly Interface" />
              </div>
              <h4>Flexible Survey Options</h4>
              <p>
                Whether you're running a quick poll or an in-depth research
                survey, our platform adapts to your needs
              </p>
            </div>
            <div className="d-flex justify-content-center w-100 flex-wrap text-center px-3 box">
              <div className="d-inline-flex p-3 mb-3 image">
                <img src={WhySurveyImg3} alt="User-Friendly Interface" />
              </div>
              <h4>Targeted Reach</h4>
              <p>
                Ensure your survey reaches the right audience, enhancing the
                quality and relevance of the data you collect
              </p>
            </div>
            <div className="d-flex justify-content-center w-100 flex-wrap text-center px-3 box">
              <div className="d-inline-flex p-3 mb-3 image">
                <img src={WhySurveyImg4} alt="User-Friendly Interface" />
              </div>
              <h4>Real-Time Analytics</h4>
              <p>
                Get real-time insights into your survey results, enabling you to
                act quickly on the data you receive
              </p>
            </div>
          </Slider>
        </Container>
      </div>
    );
  }
}

export default WhySurvey;
