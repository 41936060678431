import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SurveyEaseImg from "../../../assets/img/survey-ease.png";
import SurveyPrecisionImg from "../../../assets/img/survey-precision.png";

class MainSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="main-section-container d-block w-100 pb-5">
        <Container>
          <Row className="align-items-center justify-content-center border-bottom mb-5 pb-5">
            <Col md={5}>
              <div className="d-block text-center section-img">
                <img src={SurveyEaseImg} alt="Create Surveys with Ease" />
              </div>
            </Col>
            <Col md={7}>
              <div className="d-inline-flex flex-wrap text">
                <h3 className="d-block mt-4 mt-md-0">Create Surveys with Ease</h3>
                <p>
                  Our platform offers a wide variety of question formats,
                  including multiple-choice, open-ended, rating scales, and
                  more. Mix and match these to create surveys that truly capture
                  the information you need
                </p>
                <p>
                  Customize your surveys with advanced logic and branching
                  options. This allows you to guide respondents through
                  different paths based on their answers, ensuring that each
                  respondent only sees the questions most relevant to them.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center mb-5 pb-5">
            <Col md={5} className="order-sm-1">
              <div className="d-block text-center section-img">
                <img
                  src={SurveyPrecisionImg}
                  alt="Target Your Audience with Precision"
                />
              </div>
            </Col>
            <Col md={7} className="order-sm-0">
              <div className="d-inline-flex flex-wrap text">
                <h3 className="d-block mt-4 mt-md-0 ">High quality respondent</h3>
                <p>
                  Define your target audience with precision. SurvHaNa lets you
                  choose from a range of criteria such as demographics,
                  location, behavior, and more, ensuring your survey reaches the
                  people who matter most.
                </p>
                <p>
                  Our surveys are fully optimized for mobile devices, allowing
                  respondents to easily complete them on the go. This increases
                  participation rates and ensures you get timely responses.
                </p>
              </div>
            </Col>
          </Row>
          {/* <div className="d-block mb-5 pb-5 section-box">
            <div className="d-flex flex-wrap w-100 contents">
              <h3>Create Surveys with Ease</h3>
              <div className="d-flex w-100 flex-wrap align-items-center">
                <div className="d-inline-flex section-img">
                  <img src={SurveyEaseImg} alt="Create Surveys with Ease" />
                </div>
                <div className="d-inline-flex flex-wrap text">
                  <p>
                    Our platform offers a wide variety of question formats,
                    including multiple-choice, open-ended, rating scales, and
                    more. Mix and match these to create surveys that truly
                    capture the information you need
                  </p>
                  <p>
                    Customize your surveys with advanced logic and branching
                    options. This allows you to guide respondents through
                    different paths based on their answers, ensuring that each
                    respondent only sees the questions most relevant to them.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="d-block border-bottom-none section-box">
            <div className="d-flex flex-wrap w-100 contents">
              <h3>Target Your Audience with Precision</h3>
              <div className="d-flex w-100 flex-wrap align-items-center">
                <div className="d-inline-flex section-img">
                  <img
                    src={SurveyPrecisionImg}
                    alt="Target Your Audience with Precision"
                  />
                </div>
                <div className="d-inline-flex flex-wrap text">
                  <p>
                    Define your target audience with precision. SurvHaNa lets
                    you choose from a range of criteria such as demographics,
                    location, behavior, and more, ensuring your survey reaches
                    the people who matter most.
                  </p>
                  <p>
                    Our surveys are fully optimized for mobile devices, allowing
                    respondents to easily complete them on the go. This
                    increases participation rates and ensures you get timely
                    responses.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </Container>
      </div>
    );
  }
}

export default MainSection;
