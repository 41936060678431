import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ResearchBanner from "../../../assets/img/research-project/research-banner.png";

class MainBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleEmailClick = () => {
    window.location.href = "mailto:jasmin@survhana.com";
  };

  render() {
    return (
      <div className="main-banner-container d-block w-100 pt-1 pb-5">
        <Container>
          <Row>
            <Col md={6} className="order-md-1">
              <div className="d-flex align-items-center justify-content-center w-100 banner-image">
                <img src={ResearchBanner} alt="Main Banner" />
              </div>
            </Col>
            <Col md={6} className="order-md-0">
              <div className="d-flex w-100 h-100 align-items-center flex-wrap mt-4 mt-md-0 pt-md-5 banner-contents">
                <div className="d-flex w-100 flex-wrap">
                  <h2 className="d-flex w-100">
                  Find verified high quality Indian participants for user research
                  </h2>
                  <p className="d-flex w-100">
                  SurvHaNa makes it easier to recruit high quality Indian participants from its large panel to complete research project in no time
                  </p>
                  {/* <button
                    className="btn btn-primary"
                    onClick={this.handleEmailClick}
                  >
                    Book a Demo
                  </button> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MainBanner;
