import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.state;
    return (
      <>
        <div className="pricing-section-container d-block w-100 pb-5">
          <Container>
            <div className="d-block  text-center pb-md-5 pb-3 heading w-75 m-auto">
              Find out the cost of recruiting participants
            </div>
            <div className="d-block w-100 text-center pb-4 contents">
              <p>
                Get started with pay-as-you-go pricing. Or get discounts with
                any plan or upfront commitment.
              </p>
            </div>
            <div className="table-section text-center">
              <h3>Find the plan that's right for you</h3>
              <div className="mt-4">
                <table className="table table-bordered rounded">
                  <thead className="table-light">
                    <tr>
                      <th></th>
                      <th>Pay As You Go</th>
                      <th>Basic</th>
                      <th>Advanced</th>
                      <th>Enterprise</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Annual billing discount</td>
                      <td>N/A</td>
                      <td>10% </td>
                      <td>20% </td>
                      <td>Custom</td>
                    </tr>
                    <tr>
                      <td>Upfront annual commitment</td>
                      <td>N/A</td>
                      <td>$2160</td>
                      <td>$8640</td>
                      <td>Custom</td>
                    </tr>
                    <tr>
                      <td>
                        Moderated complete{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>$40 B2C / $80 B2B</td>
                      <td>$36 B2C / $72 B2B</td>
                      <td>$32 B2C / $64 B2B</td>
                      <td>Custom</td>
                    </tr>
                    <tr>
                      <td>
                        Unmoderated complete{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>N/A</td>
                      <td>$22.50 B2C / $45 B2B</td>
                      <td>$20 B2C / $40 B2B </td>
                      <td>Custom</td>
                    </tr>
                    <tr>
                      <td>
                        Survey complete{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>N/A</td>
                      <td>Coming soon!</td>
                      <td>Coming soon!</td>
                      <td>Custom</td>
                    </tr>
                    <tr>
                      <td>
                        Number of seats{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>
                        Cancel any time{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td> </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>As per contract</td>
                    </tr>
                    <tr>
                      <td>
                        Incentives funded separately{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Calendar integration{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Zoom integration{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Secure messaging{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Completion URLs{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Lock in rates for one year{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        NDA & document signing{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Recontact database{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Filter Past Participants{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Single Sign-on Login{" "}
                        <span
                          className="d-inline-block"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          title="Disabled tooltip"
                        >
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fas fa-check-circle"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pricing-faq">
              <h3 className="d-block w-100 text-center my-5">Pricing FAQ</h3>
              <Accordion className="pricing-accodian-price">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How do I pay for recruiting?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Why does Pay As You Go not offer different pricing per
                    methodology?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    How do Basic and Advanced plans work?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    What if I don’t use all of my commitment during the billing
                    period?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    What is a plan commitment?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    What if I spend all of my commitment before the end of the
                    year?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    How much does additional recruiting cost if I need to go
                    beyond what is included in a plan?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    Are incentives included in any of the plans?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    What currencies and payment methods can I use with
                    Respondent?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Pricing;
