import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="work-section-container d-block w-100 py-5">
        <Container>
          <div className="d-block w-100 text-center pb-5 heading">
            How It Works
          </div>
          <div className="mx-auto position-relative work-box">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                01
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Create Your Research Project</h3>
                <p>
                  Use our intuitive reserach project builder to describe your project. Add screening questions for the participants.
                </p>
              </div>
            </div>
            <div className="line"></div>
          </div>
          <div className="mx-auto position-relative work-box">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                02
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Define Your Audience</h3>
                <p>
                  Filter the participants based on Industries, Job Title, Seniority, Skills, and many more.
                </p>
              </div>
            </div>
            <div className="line last"></div>
          </div>
          <div className="mx-auto position-relative work-box last">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                03
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Get opinions of selected Participants</h3>
                <p>
                  We will filter out the best Participants for your research project. You can have one-on-one video/audio call with them.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default HowItWorks;
