import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="work-section-container d-block w-100 py-5">
        <Container>
          <div className="d-block w-100 text-center pb-5 heading">
            How It Works
          </div>
          <div className="mx-auto position-relative work-box">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                01
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Create Your Survey</h3>
                <p>
                  Use our intuitive survey builder to craft your survey from
                  scratch. Choose from various question types and add branching
                  logic to tailor the experience for each respondent.
                </p>
              </div>
            </div>
            <div className="line"></div>
          </div>
          <div className="mx-auto position-relative work-box">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                02
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Define Your Audience</h3>
                <p>
                  Set specific criteria to select the audience you want to
                  reach. Whether you're targeting a broad demographic or a niche
                  group, SurvHaNa makes it easy to focus your efforts.
                </p>
              </div>
            </div>
            <div className="line last"></div>
          </div>
          <div className="mx-auto position-relative work-box">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                03
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Collect Responses</h3>
                <p>
                  Once your survey is live, respondents can easily access it via
                  our mobile application. They'll fill out the survey based on
                  the logic you've set, providing you with rich, actionable
                  data.
                </p>
              </div>
            </div>
            <div className="line"></div>
          </div>
          <div className="mx-auto position-relative work-box last">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                04
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Analyze Results</h3>
                <p>
                  Access your survey responses through the SurvHaNa portal. Our
                  platform provides you with detailed insights and analytics,
                  helping you make data-driven decisions with confidence.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default HowItWorks;
