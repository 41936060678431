import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LowestCost from "../../../assets/img/research-project/lowest-cost.png";
import WhatYouNeed from "../../../assets/img/research-project/what-you-need.png";
import HighRespondent from "../../../assets/img/research-project/high-respondent.png";

class MainSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="main-section-container d-block w-100 pb-5">
        <Container>
          <Row className="align-items-center justify-content-center border-bottom mb-5 pb-5">
            <Col md={5}>
              <div className="d-block text-center section-img">
                <img src={WhatYouNeed} alt="You will get what you need" />
              </div>
            </Col>
            <Col md={7}>
              <div className="d-inline-flex flex-wrap text">
                <h3>You will get what you need</h3>
                <ul>
                  <li>
                    <p>
                      Find participants based on Industry, job, skills and much
                      more
                    </p>
                  </li>
                  <li>
                    <p>Be assured of the quality of the participants.</p>
                  </li>
                  <li>
                    <p>Create screener questions to make proper choice.</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center border-bottom mb-5 pb-5">
            <Col md={5} className="order-sm-1">
              <div className="d-block text-center section-img">
                <img src={HighRespondent} alt="High quality participants" />
              </div>
            </Col>
            <Col md={7} className="order-sm-0">
              <div className="d-inline-flex flex-wrap text">
                <h3>High quality participants</h3>
                <ul>
                  <li>
                    <p>All the participants in the panel are verified</p>
                  </li>
                  <li>
                    <p>
                      So you don’t have to worry about the quality of the
                      participants
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={5}>
              <div className="d-block text-center section-img">
                <img src={LowestCost} alt="Lowest cost in India" />
              </div>
            </Col>
            <Col md={7}>
              <div className="d-inline-flex flex-wrap text">
                <h3>Lowest cost in India</h3>
                <ul>
                  <li>
                    <p>SurvHaNa is made in india, made for india</p>
                  </li>
                  <li>
                    <p>
                      Find the high quality participants with lowest cost in
                      India
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MainSection;
