import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LowestCost from "../../../assets/img/research-project/lowest-cost.png";
import WhatYouNeed from "../../../assets/img/research-project/what-you-need.png";
import TargetPrecision from "../../../assets/img/survey/target-precision.png";

class MainSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="main-section-container d-block w-100 pb-5">
        <Container>
          <Row className="align-items-center justify-content-center border-bottom mb-5 pb-5">
            <Col md={5}>
              <div className="d-block text-center section-img">
                <img src={WhatYouNeed} alt="Create surveys with ease" />
              </div>
            </Col>
            <Col md={7}>
              <div className="d-inline-flex flex-wrap text">
                <h3>Create surveys with ease</h3>
                <ul>
                  <li>
                    <p>
                      Select questions from a variety of question formats,
                      including multiple-choice, open-ended, rating scales, and
                      more.
                    </p>
                  </li>
                  <li>
                    <p>
                      Mix and match questions to create surveys that truly
                      capture the information you need
                    </p>
                  </li>
                  <li>
                    <p>
                      Customize your surveys with advanced logic and branching
                      options..
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={5} className="order-sm-1">
              <div className="d-block text-center section-img">
                <img
                  src={TargetPrecision}
                  alt="Target your audience with precision"
                />
              </div>
            </Col>
            <Col md={7} className="order-sm-0">
              <div className="d-inline-flex flex-wrap text">
                <h3>Target your audience with precision</h3>
                <ul>
                  <li>
                    <p>
                      SurvHaNa lets you choose your audience from age range,
                      location, marital status, type of smart phone usage,
                      gender, family annual income, family size, education
                      qualification, disposable items owned, etc
                    </p>
                  </li>
                  <li>
                    <p>
                      SurvHaNa lets you add custom criteria as well. E.g. if you
                      want 100 participants who has purchased a car in last 3
                      months, you can do that.
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MainSection;
