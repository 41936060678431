import React, { Component } from 'react';
import MainBanner from './MainBanner';
import Section from './Section';
import HowItWorks from "./HowItWorks";
import WhySurvey from "./WhySurvey";

 class ResearchProject extends Component {
  render() {
    return (
      <div className="main-section-container d-block w-100 pb-5">
        <MainBanner/>
        <Section/>
        <HowItWorks />
        <WhySurvey />
      </div>
    )
  }
}

export default ResearchProject;