import React, { useState } from "react";
import { Row, Col, Container, Nav, Navbar, Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { GA } from "../utils/ga";
import { GOOGLE_ANALYTICS } from "../constants/googleAnalytics";

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const activePath = location.pathname;

  const handleNavClick = () => {
    setExpanded(false); // Close the Navbar when a link is clicked
  };

  return (
    <div className="header w-100 d-block py-3">
      <Container>
        <Row className="align-items-center">
          <Col lg={3} md={6} sm={8} xs={8}>
            <div className="logo w-100 d-block">
              <Link to="/">
                <img
                  src={Logo}
                  alt="SurvHaNa - Your Ultimate Survey Solution"
                />
              </Link>
            </div>
          </Col>
          <Col lg={7} md={6} sm={4} xs={4}>
            <Navbar
              expand="lg"
              expanded={expanded}
              onToggle={() => setExpanded(!expanded)}
              className="justify-content-end"
            >
              <Navbar.Toggle aria-controls="navbar-nav" />
              <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                <Nav onClick={handleNavClick}>
                <Link
                    to="/research-project"
                    className={
                      activePath === "/research-project"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                   For Research
                  </Link>
                  <Link
                    to="/for-survey"
                    className={
                      activePath === "/for-survey"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                   For Survey
                  </Link>
                  {/* <Link
                    to="/organization"
                    className={
                      activePath === "/organization"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    For Organizations
                  </Link> */}
                  <Link
                    to="/for-participants"
                    className={
                      activePath === "/for-participants"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    For Participants
                  </Link>
                  <Link
                    to="/pricing"
                    className={
                      activePath === "/pricing"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    Pricing
                  </Link>
                  {/* <Link
                    className="nav-link d-md-none d-flex"
                    to="https://business.survhana.com/prelogin/login"
                    target="_black"
                    onClick={() => {
                      GA.dispatchGAEvent(
                        GOOGLE_ANALYTICS.EVENT_CATEGORIES.WEBSITE,
                        GOOGLE_ANALYTICS.EVENT_ACTIONS.LOGIN_CLICKED
                      );
                    }}
                  >
                    Login
                  </Link> */}
                  <Link className="nav-link d-md-none d-flex" to="/contact-us">
                    Book a Demo
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
          <Col lg={2} md={4} sm={2} xs={2}>
            <div className="d-none d-lg-flex w-100 text-end justify-content-end header-buttons">
              {/* <button className="btn btn-secondary me-3">
                <Link
                  to="https://business.survhana.com/prelogin/login"
                  target="_black"
                  onClick={() => {
                    GA.dispatchGAEvent(
                      GOOGLE_ANALYTICS.EVENT_CATEGORIES.WEBSITE,
                      GOOGLE_ANALYTICS.EVENT_ACTIONS.LOGIN_CLICKED
                    );
                  }}
                >
                  Login
                </Link>
              </button> */}
              <button className="btn btn-primary">
                <Link to="/contact-us">Book a Demo</Link>
              </button>
            </div>
            {/* <div className="d-flex w-100 text-end justify-content-end">
              <Dropdown>
                <Dropdown.Toggle id="user-dropdown">
                  <i
                    className="fas fa-user-alt"
                    onClick={() => setExpanded(false)}
                  ></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
