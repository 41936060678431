import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import WhySurveyImg5 from ".././../../assets/img/why-survey-img5.png";
import WhySurveyImg6 from ".././../../assets/img/why-survey-img6.png";
import WhySurveyImg7 from ".././../../assets/img/why-survey-img7.png";
import WhySurveyImg8 from ".././../../assets/img/why-survey-img8.png";
import WhySurveyImg9 from ".././../../assets/img/why-survey-img9.png";

class WhySurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
      ],
    };
    return (
      <div className="survey-section-container d-block w-100 py-5">
        <Container>
          <div className="d-block w-100 text-center pb-5 heading">
            Why Be a SurvHaNa Participant
          </div>
          <Slider {...settings}>
            <div className="d-flex justify-content-center w-100 flex-wrap text-center px-3 box">
              <div className="d-inline-flex p-3 mb-3 image">
                <img src={WhySurveyImg5} alt="Influence Decisions" />
              </div>
              <h4>Influence Decisions</h4>
              <p>
                Your feedback helps shape products, services, and policies. Make
                your voice heard!
              </p>
            </div>
            <div className="d-flex justify-content-center w-100 flex-wrap text-center px-3 box">
              <div className="d-inline-flex p-3 mb-3 image">
                <img src={WhySurveyImg6} alt="Earn Rewards" />
              </div>
              <h4>Earn Rewards</h4>
              <p>
                Get rewarded for your time and input with points that can be
                exchanged for a variety of rewards
              </p>
            </div>
            <div className="d-flex justify-content-center w-100 flex-wrap text-center px-3 box">
              <div className="d-inline-flex p-3 mb-3 image">
                <img src={WhySurveyImg7} alt="Surveys that Matter" />
              </div>
              <h4>Surveys and Research that Matter</h4>
              <p>
                Receive surveys and research tailored to your interests and background,
                ensuring that your participation is always relevant
              </p>
            </div>
            <div className="d-flex justify-content-center w-100 flex-wrap text-center px-3 box">
              <div className="d-inline-flex p-3 mb-3 image">
                <img src={WhySurveyImg8} alt="Flexible Participation" />
              </div>
              <h4>Flexible Participation</h4>
              <p>
                Complete surveys and research on your schedule, from anywhere, using our
                mobile-friendly platform
              </p>
            </div>
            <div className="d-flex justify-content-center w-100 flex-wrap text-center px-3 box">
              <div className="d-inline-flex p-3 mb-3 image">
                <img src={WhySurveyImg9} alt="Privacy First" />
              </div>
              <h4>Privacy First</h4>
              <p>
                Your information is secure with us. We respect your privacy and
                will only share your responses in a way that maintains your
                anonymity
              </p>
            </div>
          </Slider>
        </Container>
      </div>
    );
  }
}

export default WhySurvey;
