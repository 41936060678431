import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SurveyEaseImg from "../../assets/img/survey-ease.png";
import SurveyPrecisionImg from "../../assets/img/survey-precision.png";
import MainBanner from "./MainBanner";
import Section from "./Section";

class Home extends Component {
  render() {
    return (
      <div className="main-section-container d-block w-100 pb-5">
        <MainBanner />
        <Section />
      </div>
    );
  }
}
export default Home;
