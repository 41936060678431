import React, { Component } from "react";
import { Container } from "react-bootstrap";

class GetStartedNow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleEmailClick = () => {
    window.location.href = "mailto:jasmin@survhana.com";
  };

  render() {
    return (
      <div className="survey-section-container d-block w-100 pb-5">
        <Container>
          <div className="d-block w-100 text-center pb-md-5 pb-3 heading">
            Get Started Now
          </div>
          <div className="d-block w-100 text-center pb-4 contents">
            <p>
              Ready to start sharing your insights and earning rewards? Sign up
              today and become a part of the SurvHaNa community. Your voice has
              the power to make a difference—join us and start earning rewards
              for sharing your opinions!
            </p>
            <strong>
              Join SurvHaNa and start receiving surveys that matter to you.
            </strong>
          </div>
          <div className="d-block w-100 text-center">
            <a href="/contact-us" className="btn btn-primary">Join Now</a>
          </div>
        </Container>
      </div>
    );
  }
}

export default GetStartedNow;
