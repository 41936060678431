import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PaidSurvey from "../../../assets/img/home/paid-survey.png";
import RecruitSurvey from "../../../assets/img/home/recruit-survey.png";
import RecruitQuality from "../../../assets/img/home/recruit-quality.png";

class MainSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="main-section-container d-block w-100 pb-5">
        <Container>
          <Row className="align-items-center justify-content-center border-bottom mb-5 pb-5">
            <Col md={5}>
              <div className="d-block text-center section-img">
                <img src={RecruitSurvey} alt="Create Surveys with Ease" />
              </div>
            </Col>
            <Col md={7}>
              <div className="d-inline-flex flex-wrap text">
                <h3 className="d-block mt-4 mt-md-0">
                  Recruit survey participant for your quantitative research in
                  India
                </h3>
                <ul>
                  <li>
                    <p>
                      Find verified survey participants in no time that are
                      ready to share insights.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      Target your survey based on age, state of residential,
                      marital status, type of phones, gender, annual income,
                      family status, education level, disposable items owned
                      etc.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      Create your own custom criteria to filter out participants.
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center border-bottom mb-5 pb-5">
            <Col md={5} className="order-sm-1">
              <div className="d-block text-center section-img">
                <img
                  src={RecruitQuality}
                  alt="Target Your Audience with Precision"
                />
              </div>
            </Col>
            <Col md={7} className="order-sm-0">
              <div className="d-inline-flex flex-wrap text">
                <h3 className="d-block mt-4 mt-md-0">
                  Recruit high quality participants for your qualitative
                  research in India
                </h3>
                <ul>
                  <li>
                    <p>
                      Find verified research  participants in no time that are
                      ready to share insights.
                    </p>
                  </li>
                  <li>
                    <p>
                      Find research participants  based on industry, job, skills and many
                      more.
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={5}>
              <div className="d-block text-center section-img">
                <img src={PaidSurvey} alt="Create Surveys with Ease" />
              </div>
            </Col>
            <Col md={7}>
              <div className="d-inline-flex flex-wrap text">
                <h3 className="d-block mt-4 mt-md-0">
                  Participate in paid survey and paid research by signing up
                </h3>
                <ul>
                  <li>
                    <p>Earn money by taking part in research and survey. </p>
                  </li>
                  <li>
                    <p>
                      Signup via our mobile application and start right away.{" "}
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MainSection;
